import { IconType } from 'react-icons';
import {
  FaShieldAlt,
  FaInfoCircle,
  FaDatabase,
  FaUserLock,
  FaCookieBite,
  FaGlobe,
  FaUserShield,
  FaEdit,
  FaEnvelope
} from 'react-icons/fa';

interface Section {
  title: string;
  content: string;
  icon: IconType;
}

export const sections: Section[] = [
  {
    title: "Introduction",
    content: `This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your data when you use our voice transcription service at https://voice-transcribe.com. By using our Service, you agree to the collection and use of information in accordance with this policy.`,
    icon: FaInfoCircle
  },
  {
    title: "Information Collection",
    content: `We collect several types of information for various purposes to provide and improve our Service to you. Types of data collected may include: Personal Data (e.g., email address, name), Usage Data (e.g., IP address, browser type), and Audio Data (the content you upload for transcription).`,
    icon: FaDatabase
  },
  {
    title: "Use of Data",
    content: `We use the collected data for various purposes, including: Providing and maintaining our Service, notifying you about changes to our Service, providing customer support, gathering analysis or valuable information so that we can improve our Service, monitoring the usage of our Service, and detecting, preventing and addressing technical issues.`,
    icon: FaUserLock
  },
  {
    title: "Data Security",
    content: `We strive to store data in a secure manner and take steps to follow generally accepted security practices to safeguard your information. Our approach to security may involve a range of practices and protocols, which are continually evaluated and adjusted as needed. While we take reasonable precautions based on current industry standards, the specific measures we implement may vary depending on various factors.`,
    icon: FaShieldAlt
  },
  {
    title: "Cookies and Tracking",
    content: `We may utilize cookies, browser local storage and various tracking technologies to monitor activity on our Service and store certain information. These tools may include small files containing data, which might include identifiers or other information. The exact nature and use of such technologies may vary and are subject to change over time. While these tools may collect certain types of data, their functionality, purpose, and usage can depend on various factors.`,
    icon: FaCookieBite
  },
  {
    title: "Third-Party Services",
    content: `While using third-party services, we aim to select reputable providers who offer solutions that align with industry standards and claim to protect data. However, we cannot verify or guarantee the security practices of these third parties. Our reliance on these services is based on their representations, and we are not responsible for any issues that may arise from their use.Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`,
    icon: FaGlobe
  },
  {
    title: "Changes to This Privacy Policy",
    content: `We may revise or update our Privacy Policy periodically, as we deem necessary. We encourage you to check this Privacy Policy occasionally to stay informed of any changes. Any modifications to the Privacy Policy will take effect once posted on this page, though we do not guarantee that we will provide advance notice or highlight the specific nature of those changes.`,
    icon: FaEdit
  },
  {
    title: "Contact Us",
    content: `If you have any questions about this Privacy Policy, please contact us at: admin@data-elysium.ca.`,
    icon: FaEnvelope
  }
];
import React from "react";
import { Link } from "react-router-dom";
import Simpleheader from "src/Common/Header/Simpleheader";
import { Button } from "@mui/material";

const PaymentSuccess = () => {
  return (
    <div className="bg-body-600 min-h-screen p-4 lg:p-8">
      <Simpleheader />
      <div className="flex flex-col items-center justify-center min-h-[calc(70vh)]">
        <h1 className="text-3xl font-bold text-list-600">
          Payment Successful!
        </h1>
        <p className="text-lg text-contenthead-800 mt-4">
          Your trial has been activated Successfully.
        </p>
        <Button component="label" variant="contained" className="sign-up-btn  hover:to-heading-800 bg-gradient-to-r  sm:w-auto shadow-none from-heading-800 from-head-600 to-low-400 text-base leading-6 text-white bg-white  font-bold py-2 px-10 rounded-lg mt-4 w-auto h-auto capitalize">
          <Link
            to="/upload-file"
            className="text-base  no-underline text-white py-2"
          >
            Click here to upload files
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccess;

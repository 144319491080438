import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Paper, CircularProgress, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TranscriptionStatusBox = ({ title, details, status, handleTranscribeAgain = null, handleOpenModal=null }:
    { title: string, details: string, status: string, handleTranscribeAgain: any, handleOpenModal: any }) => {

    return (
        <div className="flex items-center justify-center  mt-4">
            <Box
                justifyContent="center"
                alignItems="center"
                
            >
                <Paper elevation={3} style={{ position: "relative", padding: '20px', maxWidth: '400px', textAlign: 'center' }}>
                    {status === "completed" || status === "cancelled" || <CircularProgress />}
                    
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>

                    <Typography variant="body1">
                        {details}
                    </Typography>

                    {status === "completed" &&
                        <>
                            <Button
                                className=" bg-gradient-to-r from-green-800 to-green-600 text-white text-base hover:to-green-800 font-bold px-10 py-2 rounded-lg border-none capitalize mt-6"
                                onClick={handleOpenModal}
                            >
                                Download Transcription
                            </Button>

                            <Button
                                className="bg-gradient-to-r from-heading-800  to-low-400 text-white text-base hover:to-heading-800 font-bold px-10 py-2 rounded-lg border-none capitalize mt-6"
                                onClick={handleTranscribeAgain}
                            >
                                Transcribe Another!
                            </Button>
                        </>
                    }

                    {status === "cancelled" &&
                        <>
                            <Button
                                className="bg-gradient-to-r from-heading-800  to-low-400 text-white text-base hover:to-heading-800 font-bold px-10 py-2 rounded-lg border-none capitalize mt-6"
                                onClick={handleTranscribeAgain}
                            >
                                Try again!
                            </Button>
                        </>
                    }

                </Paper>
            </Box>
        </div>
    );
}

export default TranscriptionStatusBox;
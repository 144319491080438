import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import { sections } from './contactData';

interface ContactsPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactsPopup: React.FC<ContactsPopupProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const renderContactContent = (content: string) => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const parts = content.split(emailRegex);
    const matches = content.match(emailRegex);

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {matches && matches[index] && (
          <a href={`mailto:${matches[index]}`} className="text-blue-600 hover:underline">
            {matches[index]}
          </a>
        )}
      </React.Fragment>
    ));
  };

  const IconComponent = sections[0]?.icon;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white rounded-lg shadow-xl max-w-2xl w-full overflow-hidden flex flex-col"
        >
          <div className="sticky top-0 bg-gradient-to-r from-heading-800 to-purple-700 z-10 flex justify-between items-center p-6 border-b">
            <h2 className="text-3xl font-bold text-white tracking-wide">
              Contact Us
            </h2>
            <button
              onClick={onClose}
              className="text-white hover:text-gray-200 transition-colors p-2 rounded-full hover:bg-purple-700"
            >
              <FaTimes size={24} />
            </button>
          </div>
          <div className="p-6">
            <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
              <h3 className="text-2xl font-semibold mb-4 flex items-center text-heading-800">
                {IconComponent && <IconComponent className="mr-3 text-heading-800" size={24} />}
                {sections[0]?.title}
              </h3>
              <p className="text-gray-700 leading-relaxed text-lg">
                {sections[0]?.content && renderContactContent(sections[0].content)}
              </p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContactsPopup;
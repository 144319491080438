import { Avatar } from "@mui/material";
import React, { useState } from "react";

interface AccordionProps {
  question: string;
  answer: string;
}

const Accordion: React.FC<AccordionProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="w-full bg-faqbox-600 p-5 md:p-8 cursor-pointer rounded-xl">
        <div
          className="flex justify-between items-center gap-12"
          onClick={toggleAccordion}
        >
          <span className="text-lg md:text-2xl font-medium text-contenthead-800">
            {question}
          </span>

          <div className="bg-white px-2 md:px-4 py-2 md:py-4 rounded-lg ">
            <Avatar
              alt="plus.svg"
              src="./assets/Icons/plus.svg"
              sx={{ width: 18, height: "auto" }}
              className={`rounded-none transform transition-transform  ${
                isOpen ? "rotate-45" : ""
              }`}
            />
          </div>
        </div>

        {isOpen && (
          <div className="pt-6 bg-faqbox-600 border-t border-gray-200 mt-6">
            <p className="text-base text-content-600">{answer}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;

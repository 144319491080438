import { getJSON } from "src/API/api";
import {
  CLIENT_ID,
  COGNITO_DOMAIN,
  COGNITO_USER_INFO_URL,
  LOGOUT_URI,
  REDIRECT_URI,
  SIGNIN_URL,
  TOKEN_URL,
} from "src/utils/constant";

export async function exchangeCodeForTokens(code: string) {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", CLIENT_ID);
  params.append("code", code);
  params.append("redirect_uri", REDIRECT_URI);

  try {
    const response = await fetch(TOKEN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });

    if (!response.ok) {
      throw new Error("Failed to exchange code for tokens");
    }

    const data = await response.json();
    saveTokens(data);

    // Clear the code from the URL
    window.history.replaceState({}, document.title, REDIRECT_URI);
  } catch (error) {
    console.error("Error exchanging code for tokens:", error);
  }
}

// Function to save tokens with expiration time
export async function saveTokens(tokenData: Record<string, any>) {
  localStorage.setItem("access_token", tokenData.access_token);
  localStorage.setItem("id_token", tokenData.id_token);
  if (tokenData.refresh_token) {
    localStorage.setItem("refresh_token", tokenData.refresh_token);
  }
}

// Function to refresh tokens
export async function refreshTokens() {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    console.error("No refresh token found.");
    return;
  }

  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("client_id", CLIENT_ID);
  params.append("refresh_token", refreshToken);

  try {
    const response = await fetch(TOKEN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });

    if (!response.ok) {
      throw new Error("Failed to refresh tokens");
    }

    const data = await response.json();
    saveTokens(data);
    // console.log("Tokens refreshed successfully");
  } catch (error) {
    console.error("Error refreshing tokens:", error);
  }
}

export async function logout() {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    // console.log("No Refresh Token found.");
  }

  try {
    const response = await fetch(`https://${COGNITO_DOMAIN}/oauth2/revoke`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `token=${refreshToken}&client_id=${CLIENT_ID}`,
    });

    if (response.ok) {
      // console.log("Token revoked successfully.");
    } else {
      console.error("Failed to revoke token:", response.statusText);
    }
  } catch (error) {
    console.error("Error revoking token:", error);
  } finally {
    // console.log("Reached finally");
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_expiration");
    window.location.href = `https://${COGNITO_DOMAIN}/logout?client_id=${CLIENT_ID}&logout_uri=${LOGOUT_URI}`;
  }
}
export async function forceLogout() {
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    // console.log("No Refresh Token found.");
  }

  try {
    const response = await fetch(`https://${COGNITO_DOMAIN}/oauth2/revoke`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `token=${refreshToken}&client_id=${CLIENT_ID}`,
    });

    if (response.ok) {
      // console.log("Token revoked successfully.");
    } else {
      console.error("Failed to revoke token:", response.statusText);
    }
  } catch (error) {
    console.error("Error revoking token:", error);
  } finally {
    // console.log("Reached finally");
    localStorage.removeItem("id_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_expiration");
    window.location.href = SIGNIN_URL;
  }
}

export function isUserLoggedIn(): boolean {
  const accessToken = localStorage.getItem("access_token");
  const idToken = localStorage.getItem("id_token");
  return !!accessToken && !!idToken;
}

export const isAuthenticated = async (): Promise<boolean> =>
  !!(await getJSON<any>(COGNITO_USER_INFO_URL));

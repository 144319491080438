import { Avatar, Tooltip } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import GetAppIcon from "@mui/icons-material/GetApp";
import { getJSON } from "src/API/api";
import ExportModal from "./ExportModel";
import { API_TASKS } from "src/utils/constant";
import { decodeFilename } from "src/API/download";
import { shortenFileName } from '../../utils/fileUtils';
import { reloadContext } from "../Uploadfile/Uploadfile";

const Uploadshortcutsection = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [files, setFiles] = useState({
    pending: [],
    ongoing: [],
    completed: [],
    cancelled: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [selectedFilename, setSelectedFilename] = useState<string | null>(null);
  const [reload,setReload] = useContext(reloadContext)

  useEffect(() => {
    const fetchFiles = async () => {    
      setFiles(await getJSON(API_TASKS));
    };
    fetchFiles();
  }, [reload]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSubDropdown = (category: string) => {
    setActiveDropdown(activeDropdown === category ? null : category);
  };

  const handleDownload = (file_id: string, file_name: string) => {
    setSelectedFile(file_id);
    setSelectedFilename(decodeFilename(file_name));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setSelectedFilename(null);
  };

  return (
    <div className="bg-white px-6 py-6 rounded-3xl md:w-80">
      <h3 className="text-list-600 font-semibold text-base leading-6">
        Shortcuts
      </h3>
      <div>
        <button
          onClick={toggleDropdown}
          className="flex items-center justify-between gap-2 text-base leading-6 py-2 px-4 font-medium rounded-md text-contenthead-800 bg-body-600 my-4 w-full"
        >
          <div className="flex items-center gap-2">
            <Avatar
              alt=""
              sx={{ width: 21, height: "auto" }}
              src="./assets/Icons/copy.svg"
              className="rounded-none"
            />
            Recent Files
          </div>
          {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
        {isDropdownOpen && (
          <div className="ml-6 mt-2">
            {["pending", "ongoing", "completed", "cancelled"].map(
              (category) => (
                <div key={category}>
                  <button
                    onClick={() => toggleSubDropdown(category)}
                    className="flex items-center justify-between text-base leading-6 py-2 px-4 font-medium rounded-md text-contenthead-800 bg-body-300 w-full"
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                    {activeDropdown === category ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </button>
                  {activeDropdown === category && (
                    <div className="ml-6 mt-2">
                      {files[category].map(
                        (file: { file_name: string; file_id: string }) => (
                          <Tooltip title={file.file_name} placement="right">
                            <div
                              key={file.file_id}
                              className="flex items-center justify-between text-base leading-6 py-1"
                            >
                              <div className="flex items-center gap-2">
                                <AudiotrackIcon />
                                {shortenFileName(file.file_name, 17)}
                              </div>
                              {category === "completed" && (
                                <GetAppIcon
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleDownload(file.file_id, file.file_name)
                                  }
                                />
                              )}
                            </div>
                          </Tooltip>

                        )
                      )}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div>

      {isModalOpen && (
        <ExportModal
          open={isModalOpen}
          onClose={handleCloseModal}
          file_id={selectedFile}
          filename={selectedFilename}
        />
      )}
    </div>
  );
};

export default Uploadshortcutsection;

import { Avatar, CircularProgress } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { DEFAULT_BALANCE, SUBSCRIPTION, SUBSCRIPTION_TYPE_MANAGEMENT_URL } from "src/utils/constant";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "11px",
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.mode === "light" ? "#190F47" : "#308fe8",
  },
}));

const Uploadtimesection = ({ balance, subscriptionType }: { balance: number, subscriptionType: string }) => {
  let balanceProgressDivisor;
  if (subscriptionType === SUBSCRIPTION.NEW) {
    balanceProgressDivisor = 600 / 100;
  }
  else if (subscriptionType === SUBSCRIPTION.TRIAL) {
    balanceProgressDivisor = 30 * 24 * 60 / 100;
  }
  else if (subscriptionType === SUBSCRIPTION.LIMITED) {
    balanceProgressDivisor = 1800 / 100;
  }
  else if (subscriptionType === SUBSCRIPTION.UNLIMITED) {
    balanceProgressDivisor = 30 * 24 * 60 / 100;
  }
  else {
    balanceProgressDivisor = 18;
  }

  return (
    <>
      <div className="bg-white py-6 px-6 rounded-3xl md:w-80">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-semibold text-base leading-6 text-list-600">
            Remaining Minutes
          </h3>
          {subscriptionType === SUBSCRIPTION.UNLIMITED ? <Avatar
            alt=""
            sx={{ width: 21, height: "auto" }}
            src="./assets/Icons/unlimited.svg"
            className="rounded-none"
          /> : (
            <span className="text-contenthead-800 text-sm font-semibold leading-5">
              {balance === DEFAULT_BALANCE ? (
                <CircularProgress size={15} />
              ) : (
                balance
              )}
              /{((subscriptionType === SUBSCRIPTION.NEW)) ? 600 : ((subscriptionType === SUBSCRIPTION.UNLIMITED) || ((subscriptionType === SUBSCRIPTION.TRIAL) ) ? 30 * 24 * 60 : 1800)}
              mins
            </span>)}
        </div>
        <div className="progress  bg-gray-200 rounded-full mb-8">
          <BorderLinearProgress variant="determinate" value={balance / balanceProgressDivisor} />
        </div>
        {((subscriptionType === SUBSCRIPTION.NEW) ||  (subscriptionType === SUBSCRIPTION.LIMITED)) ? (
          <div className="flex justify-center">
            <a
              href="https://voice-transcribe.com/#pricing-section"
              className="bg-gradient-to-r from-heading-800  to-low-400  flex items-center  gap-2  text-base font-bold py-2 px-4 rounded-lg text-white border-2 border-high-400 hover:to-heading-800">
              <Avatar
                alt=""
                sx={{ width: 21, height: "auto" }}
                src="./assets/Icons/file-vector.svg"
                className="rounded-none"
              />
              Go Unlimited
            </a>
          </div>) : " "}
      </div>
    </>
  );
};

export default Uploadtimesection;

import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  Box,
  Typography,
  Paper,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ExportModal from "./ExportModel";
import { getS3PresignedUrl, uploadToS3 } from "src/API/upload";
import { checkFileStatus } from "src/API/status";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortenFileName } from "../../utils/fileUtils";
import TranscriptionStatusBox from "./TranscriptionStatusBox";
import { reloadContext } from "../Uploadfile/Uploadfile";

const VisuallyHiddenInput = styled("input")({
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface File {
  name: string;
  file: File;
  progress: number;
}

const Uploadfilesection = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isTranscribePressed, setIsTranscribePressed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [status, setStatus] = useState("none");
  const [IsFileSelected, setIsFileSelected] = useState(false);
  const [reload, setReload] = useContext(reloadContext);
  const [previousStatus, setPreviousStatus] = useState("");
  const [currentFileId, setCurrentFileId] = useState(null);
  const [currentFileName, setCurrentFileName] = useState(null);
  const showErrorToast = (message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const MAX_FILE_SIZE = 100 * 1024 * 1024;

  const isValidFileType = (file) => {
    const validTypes = [
      "audio/mpeg", // mp3
      "audio/wav", // WAV
      "audio/ogg", // OGG, OPUS, OGA
      "audio/mp4",
      "audio/x-m4a", // M4A, ALAC
      "audio/vnd.dlna.adts", // AAC
      "audio/flac", // FLAC
      "audio/x-ms-wma", // WMA
      "audio/aiff", // AIFF
      "audio/basic", // AU
      "video/webm", // WEBM
    ];
    return validTypes.includes(file.type);
  };

  const isValidFileSize = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };

  useEffect(() => {
    if (isTranscribing) {
      setIsTranscribePressed(true);
      const files = selectedFiles.map((file) => file.file);
      getS3PresignedUrl(files[0].name, files[0].type)
        .then((response: any) => {
          setCurrentFileId(
            response["presigned_post"]["fields"]["x-amz-meta-file-id"]
          );
          setCurrentFileName(
            response["presigned_post"]["fields"]["x-amz-meta-file-name"]
          );
          uploadToS3(files[0], response.presigned_post).then(
            (response: any) => {
              setIsTranscribing(false);
              setStatus("uploading");
              setCheckStatus(true);
            }
          );
        })
        .catch((error: any) => {
          showErrorToast("Upload Failed. Please try again.");
          handleRemoveFile(selectedFiles.length - 1);

          console.error(error);
          setIsTranscribing(false);
        });
    }
  }, [selectedFiles, isTranscribing]);

  useEffect(() => {
    if (checkStatus) {
      const intervalId = setInterval(async () => {
        try {
          const statusResponse = await checkFileStatus(currentFileId);
          if (statusResponse.error) {
            throw new Error(statusResponse.error);
          }
          setStatus((prevStatus) => {
            if (statusResponse.status !== prevStatus) {
              setReload((pre) => !pre);
              return statusResponse.status;
            }
            return prevStatus;
          });
          if (
            statusResponse.status === "completed" ||
            statusResponse.status === "cancelled"
          ) {
            setCheckStatus(false);
          }
          setPreviousStatus(statusResponse.status);
          setStatus(statusResponse.status);
        } catch (error) {
          console.error("Error checking file status:", error);
        }
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [checkStatus, status, reload]);

  const resetState = () => {
    setCheckStatus(false);
    setStatus("none");
    setIsFileSelected(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as unknown as File[];
    const validFiles = files.filter(
      (file) => isValidFileType(file) && isValidFileSize(file)
    );
    let invalidFilesByType = files.filter((file) => !isValidFileType(file));
    let invalidFilesBySize = files.filter((file) => !isValidFileSize(file));

    const newFiles = validFiles.map((file) => ({
      file,
      name: file.name,
      progress: 0,
    }));

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setIsTranscribePressed(false);
    resetState();

    if (newFiles.length > 1) {
      showErrorToast("We currently support only 1 file at a time");
      setSelectedFiles([]);
    }

    if (invalidFilesByType.length > 0) {
      showErrorToast("Unsupported File Format");
      setSelectedFiles([]);
    }

    if (invalidFilesBySize.length > 0) {
      showErrorToast("Max File Size Exceeded (100MB)");
      setSelectedFiles([]);
    }

    if (
      invalidFilesByType.length == 0 &&
      invalidFilesBySize.length == 0 &&
      newFiles.length == 1
    ) {
      setIsFileSelected(true);
    }

    invalidFilesByType = [];
    invalidFilesBySize = [];
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files) as unknown as File[];
    const validFiles = files.filter(
      (file) => isValidFileType(file) && isValidFileSize(file)
    );
    let invalidFilesByType = files.filter((file) => !isValidFileType(file));
    let invalidFilesBySize = files.filter((file) => !isValidFileSize(file));

    const newFiles = validFiles.map((file) => ({
      file,
      name: file.name,
      progress: 0,
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setIsTranscribePressed(false);
    if (newFiles.length > 1) {
      showErrorToast("We currently support only 1 file at a time");
      setSelectedFiles([]);
    }

    if (invalidFilesByType.length > 0) {
      showErrorToast("Unsupported File Format");
      setSelectedFiles([]);
    }

    if (invalidFilesBySize.length > 0) {
      showErrorToast("Max File Size Exceeded (100MB)");
      setSelectedFiles([]);
    }

    if (
      invalidFilesByType.length == 0 &&
      invalidFilesBySize.length == 0 &&
      newFiles.length == 1
    ) {
      setIsFileSelected(true);
    }

    invalidFilesByType = [];
    invalidFilesBySize = [];
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    resetState();
  };

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleTranscribe = () => {
    setIsTranscribing(true);
    setStatus("uploading");
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="w-full">
        <div className="bg-white rounded-3xl w-full p-4 lg:p-8 md:h-[calc(100vh-155px)]">
          <h2 className="text-2xl font-semibold leading-9 text-list-600">
            Welcome To Voice Transcribe
          </h2>

          <div className="flex justify-center h-auto">
            {selectedFiles.length === 0 && (
              <div
                className={`bg-body-600 px-4 py-4 justify-center items-center rounded-2xl w-full xl:w-1/2 mt-7 md:my-7${
                  isDragging ? "border-blue-500" : ""
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <div className="border-2 border-dashed border-dragcontent-700 text-center rounded-lg cursor-pointer p-4">
                  <div className="flex justify-center items-center mt-4">
                    <Avatar
                      alt="upload"
                      sx={{ width: 28, height: "auto" }}
                      src="./assets/Icons/upload.svg"
                      className="rounded-none"
                    />
                  </div>
                  <p className="text-base font-bold leading-6 text-dragcontent-700 mt-2">
                    Drag and drop your files here
                  </p>
                  <div className="text-sm font-normal leading-5 text-contenthead-800 mt-2 flex justify-center text-center items-center">
                    <strong>
                      File Supported: Any Audio File Format (100MB limit)
                    </strong>
                    <Tooltip
                      title="mp3, wav, m4a, ogg, aac, flac, webm, wma, opus, oga, aiff, au, alac"
                      placement="right"
                    >
                      <Avatar
                        alt="info"
                        sx={{ width: 20, height: "auto" }}
                        src="./assets/Icons/vector.svg"
                        className="rounded-none mx-1"
                      />
                    </Tooltip>
                  </div>
                  <Button
                    component="label"
                    variant="contained"
                    className="sign-up-btn  hover:to-heading-800 bg-gradient-to-r  sm:w-auto shadow-none from-heading-800 from-head-600 to-low-400 text-base leading-6 text-white bg-white  font-bold py-2 px-10 rounded-lg mt-4 w-auto h-auto capitalize text-high-600 "
                  >
                    Browse File
                    <VisuallyHiddenInput
                      type="file"
                      multiple
                      accept=".ogg,.mp3,.wav,.m4a,.opus,.aac,.flac,.wma,.aiff,.oga,.au,.webm,.alac"
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
                <p id="apiResponse"></p>
              </div>
            )}
          </div>

          <div className="pt-4">
            <div className="space-y-4">
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-body-600 py-2 pl-5 mr-3 rounded-md relative"
                >
                  <div className="flex items-center gap-2 w-[40%]">
                    <Avatar
                      alt="Music Icon"
                      sx={{ width: 21, height: "auto" }}
                      src="./assets/Icons/music-icon.svg"
                      className="rounded-none"
                    />
                    <span className="text-base text-contenthead-800 leading-6 font-medium">
                      {shortenFileName(file.name, 50)}
                    </span>
                  </div>

                  <div className="flex items-center gap-6 mr-10">
                    {status === "completed" && (
                      <button
                        className="p-1 text-teal-700 hover:bg-gray-100 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-progressLineColor-500"
                        onClick={handleOpenModal}
                        aria-label="Download"
                      >
                        <DownloadIcon className="w-5 h-5" />
                      </button>
                    )}

                    {IsFileSelected &&
                      status == "none" &&
                      !isTranscribing &&
                      !isTranscribePressed &&
                      !checkStatus && (
                        <button
                          className="text-white bg-[#FF0000] rounded-full w-6 h-6 flex items-center justify-center"
                          onClick={() => handleRemoveFile(index)}
                        >
                          X
                        </button>
                      )}
                  </div>
                </div>
              ))}
            </div>
            {selectedFiles.length > 0 && (
              <div className="flex items-center justify-between mt-6 flex-wrap gap-6">
                {/* <div className="flex gap-4 xl:gap-8 w-full md:w-auto justify-between">
                  <select
                    id="my-select"
                    value={selectedValue}
                    onChange={handleChange}
                    className="flex rounded-lg px-2"
                  >
                    <option value="" disabled>
                      Select Language
                    </option>
                    <option value="option1">English</option>
                    <option value="option2">French</option>
                    <option value="option3">Dutch</option>
                  </select>
                </div> */}

                <div className="flex-grow"></div>
                {!isTranscribePressed && (
                  <Button
                    variant="outlined"
                    className="hover:to-heading-800 bg-gradient-to-r from-heading-800  to-low-400 text-white text-base font-bold px-10 py-2 rounded-lg border-none capitalize mr-3"
                    onClick={handleTranscribe}
                  >
                    Transcribe
                  </Button>
                )}
              </div>
            )}
          </div>

          {status === "uploading" && (
            <TranscriptionStatusBox
              title="File Uploading"
              details="Uploading file. Once the upload is complete, the transcription process will begin"
              status={status}
            />
          )}

          {status === "pending" && (
            <TranscriptionStatusBox
              title="Request In The Queue"
              details="Your transcription request is in the queue.Transcription will begin as soon as resources become available"
              status={status}
              // handleTranscribe={null}
            />
          )}
          {status === "ongoing" && (
            <TranscriptionStatusBox
              title="Transcription In Progress"
              details="Your transcription is in progress. Grab a coffee, and we'll notify you once it's done!"
              status={status}
            />
          )}
          {status === "completed" && (
            <TranscriptionStatusBox
              title="Transcription Completed"
              details="Your transcription is Completed. You can download it from below link."
              status={status}
              handleOpenModal={handleOpenModal}
              handleTranscribeAgain={handleRemoveFile}
            />
          )}
          {status === "cancelled" && (
            <TranscriptionStatusBox
              title="Transcription Failed"
              details="Your transcription has Failed for some reason. Please try again later or use another file."
              status={status}
              handleTranscribeAgain={handleRemoveFile}
            />
          )}
        </div>
      </div>
      <ExportModal
        open={isModalOpen}
        onClose={handleCloseModal}
        file_id={currentFileId}
        filename={currentFileName}
      />
    </>
  );
};

export default Uploadfilesection;

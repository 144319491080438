import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { isAuthenticated } from "./auth";
import { SIGNIN_URL } from "src/utils/constant";
import { CircularProgress, Icon } from "@mui/material";

const ProtectedRoute: React.FC = () => {
  const [auth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authStatus = await isAuthenticated();
        setAuth(authStatus);
        if (!authStatus) {
          window.location.href = SIGNIN_URL;
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
        setAuth(false);
        window.location.href = SIGNIN_URL;
      }
    };

    checkAuth();
  }, []);

  if (!auth) {
    return (
      <div className="loading-splash">
        <CircularProgress size={60} />
        <Icon className="loader-icon"></Icon>
      </div>
    );
  }

  return auth ? <Outlet /> : null;
};

export default ProtectedRoute;

import { API_USER_INFO } from "src/utils/constant";
import { getJSON } from "./api";
import { decodeUserName } from "src/utils/decodeUserName";

type UserDetailsJson = {
  user_details: {
    user_name: string;
    email: string;
    subscription_type: string;
    remain_credit: number;
  };
};

export const getUserInfo = async (): Promise<UserDetailsJson> =>
  getJSON(`${API_USER_INFO}/${decodeUserName()}`);

import Uploadtimesection from "../Uploadtimesection/Uploadtimesection";
import Uploadshortcutsection from "../Uploadshortcutsection/Uploadshortcutsection";
import Uploadfilesection from "../Uploadfilesection/Uploadfilesection";
import Simpleheader from "src/Common/Header/Simpleheader";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { exchangeCodeForTokens } from "src/Auth/auth";
import { DEFAULT_BALANCE, getSubscriptionUrl, LOCAL_STORAGE_KEYS, SUBSCRIPTION } from "src/utils/constant";
import { CircularProgress, Icon } from "@mui/material";
import { getUserInfo } from "src/API/user.ts";

export const reloadContext = createContext("");

const Uploadfile = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [balance, setBalance] = useState<number>(DEFAULT_BALANCE);
  const [isAuthHandled, setIsAuthHandled] = useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState<string>(SUBSCRIPTION.LIMITED);
  const [reload, setReload]= useState<boolean>(false);

  const handleAuth = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      await exchangeCodeForTokens(code);
    }
    setIsAuthHandled(true);
    const { user_details: userDetails } = await getUserInfo();

    setSubscriptionType(userDetails.subscription_type);

    const subscription = localStorage.getItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION);
    if (subscription && userDetails) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION);
      window.location.href = getSubscriptionUrl(
        subscription,
        userDetails.email
      );
      return;
    }
    // If subscription bought from stripe then set the balance else redirect to the homepage
    if (userDetails.remain_credit) {
      setBalance(userDetails.remain_credit);
    }
    
    setLoading(false);
  }, [location]);

  useEffect(() => {
    handleAuth();
  }, [handleAuth, reload]);

  if (loading) {
    return (
      <div className="loading-splash">
        <CircularProgress size={60} />
        <Icon className="loader-icon"></Icon>
      </div>
    );
  }

  return (
    <div>
      <reloadContext.Provider value={[reload,setReload]}>
      <div className="bg-body-600 min-h-screen p-4 lg:p-8">
        <Simpleheader />

        <main className="flex mt-6 gap-6 flex-col md:flex-row">
          {/* timeing section */}
          <div className="left-cards flex gap-6 flex-col">
            <Uploadtimesection balance={balance} subscriptionType={subscriptionType} />
            {isAuthHandled && <Uploadshortcutsection />}
          </div>
          <Uploadfilesection />
        </main>
      </div>
      </reloadContext.Provider>
    </div>
  );
};

export default Uploadfile;

import { Button } from "@mui/material";
import { useState } from "react";
import {
  LOCAL_STORAGE_KEYS,
  SIGNIN_URL,
  SUBSCRIPTION,
} from "src/utils/constant";

const Pricingsection = () => {
  const [yearUpdate, setYearUpdate] = useState<"/month" | "/yr">("/month");

  // const handleChange = (value: "/mo" | "/yr") => {
  // 	setYearUpdate(value);
  // };

  const getPricing = (isLimited: boolean) => {
    if (yearUpdate === "/month") {
      return isLimited ? "$4.99" : "$14.99";
    } else {
      return isLimited ? "$50" : "$160";
    }
  };

  const handleSubscription = (subscription: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION, subscription);
    window.location.href = SIGNIN_URL;
  };

  return (
    <div className="price-sec py-12 md:py-28 px-4" id="pricing-section">
      <div className="max-w-sm mx-auto">
        <h2 className="text-4xl md:text-6xl font-medium text-center text-list-600 mb-7">
          Pricing
        </h2>
        {/* <div className="flex justify-center bg-white p-2 md:p-3 rounded-xl gap-3 md:gap-6 items-center w-max mx-auto">
          <Button
            variant="outlined"
            onClick={() => handleChange("/mo")}
            className={` ${
              yearUpdate === "/mo"
                ? "border-none min-w-28 sm:min-w-40  bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block text-white  text-sm md:text-base py-2 rounded-lg capitalize"
                : "border-none min-w-28 sm:min-w-40 bg-gradient-to-r from-buttondeam-400 from-head-600 to-buttondeam-400 inline-block  text-sm md:text-base py-2 px-3 rounded-lg capitalize text-buttoncontent-600"
            }`}
          >
            Monthly
          </Button>
          <Button
            variant="outlined"
            // onClick={() => handleChange("/yr")}
            className={` ${
              yearUpdate === "/yr"
                ? "border-none min-w-28 sm:min-w-40 bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block text-white text-sm md:text-base py-2 rounded-lg capitalize"
                : "border-none min-w-28 sm:min-w-40 bg-gradient-to-r from-buttondeam-400 from-head-600 to-buttondeam-400 inline-block text-sm md:text-base py-2 px-3 rounded-lg capitalize text-buttoncontent-600"
            }`}
          >
            Yearly (Upcoming)
          </Button>
        </div> */}

        <div className="flex mt-12 items-end gap-4 md:gap-12 justify-between flex-wrap">
          {/* <div className=' bg-list-600 rounded-2xl flex-1'>
						<div className='bg-white rounded-xl'>
							<div className='py-6 px-6 border-b-2 border-gray-200'>
								<h4 className='text-lg md:text-2xl font-bold leading-7 text-prisingamount-600 flex justify-center'>
									Limited
								</h4>
								<strong className='text-3xl text-high-600 flex justify-center'>
									{getPricing(true)}
									<small className='text-2xl text-prisingamount-600'>
										{yearUpdate}
									</small>
								</strong>
							</div>

							<div className='gap-8 flex flex-col p-6'>
								<div className='flex items-center gap-3 tracking-widest	'>
									<img src={"./assets/Icons/right.svg"} alt='' />
									<span>Limited Transcription</span>
								</div>

								<div className='flex items-center gap-3 tracking-widest	'>
									<img src={"./assets/Icons/right.svg"} alt='' />
									<span>30 Hours</span>
								</div>

								<div className='flex items-center gap-3 tracking-widest	'>
									<img src={"./assets/Icons/right.svg"} alt='' />
									<span>All Languages</span>
								</div>

								<div className='flex justify-center mt-4'>
									<Button
										onClick={() => handleSubscription(SUBSCRIPTION.LIMITED)}
										className='capitalize w-full border-none text-white  hover:to-heading-800 bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block  rounded-xl text-2xl font-bold py-3 px-5'
									>
										Start Your Free Trial Now
									</Button>
								</div>
							</div>
						</div>
					</div> */}

          {/* second box */}

          <div className="bg-list-600 rounded-2xl flex-1">
            <h4 className="text-white font-bold text-lg md:text-2xl pt-5 pb-2 px-20 text-center">
              UNLIMITED
            </h4>
            <div className="bg-white rounded-xl">
              <div className="py-6 px-6 border-b-2 border-gray-200">
                <h4 className="text-lg md:text-2xl font-bold leading-7 text-prisingamount-600 flex justify-center"></h4>
                <strong className="text-3xl text-high-600 flex justify-center">
                  {getPricing(false)}
                  <small className="text-2xl text-prisingamount-600">
                    {yearUpdate}
                  </small>
                </strong>
              </div>

              <div className="gap-8 flex flex-col p-6">
                <div className="flex items-center gap-3 tracking-widest	">
                  <img src={"./assets/Icons/right.svg"} alt="" />
                  <span>Unlimited Transcription</span>
                </div>

                <div className="flex items-center gap-3 tracking-widest	">
                  <img src={"./assets/Icons/right.svg"} alt="" />
                  <span>Unlimited Hours</span>
                </div>

                <div className="flex items-center gap-3 tracking-widest	">
                  <img src={"./assets/Icons/right.svg"} alt="" />
                  <span>All Languages</span>
                </div>

                <div className="flex justify-center mt-4">
                  <Button
                    onClick={() => handleSubscription(SUBSCRIPTION.UNLIMITED)}
                    className="capitalize w-full border-none rounded-xl text-white  hover:to-heading-800 bg-gradient-to-r from-heading-800 from-head-600 to-low-400 inline-block text-xl font-bold py-3 px-5"
                  >
                    Start with 7 Days Free Trial
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricingsection;

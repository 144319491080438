import { forceLogout, logout, refreshTokens } from "src/Auth/auth";

const fetchProtectedAPI = async (
  url: string,
  options: RequestInit = {}
): Promise<Response> => {
  let accessToken: string | null = null;

  try {
    accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      forceLogout();
    }
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(url, options);
    if (response.status === 401) {
      await refreshTokens();
      accessToken = localStorage.getItem("access_token");
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      response = await fetch(url, options);
      if (response.status === 401) {
        forceLogout();
      }
    }
    return response;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export default fetchProtectedAPI;

import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  Box,
  Typography,
  Paper,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getS3PresignedUrl, uploadToS3 } from "src/API/upload";
import { checkFileStatus } from "src/API/status";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortenFileName } from "../../utils/fileUtils";
import TranscriptionStatusBox from "src/Components/Uploadfilesection/TranscriptionStatusBox";
import { reloadContext } from "../Uploadfile/Uploadfile";
import { SIGNUP_URL } from "src/utils/constant";

const VisuallyHiddenInput = styled("input")({
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface File {
  name: string;
  file: File;
  progress: number;
}

function TrialUploadSection() {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isTranscribePressed, setIsTranscribePressed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [status, setStatus] = useState("none");
  const [IsFileSelected, setIsFileSelected] = useState(false);
  const [reload, setReload] = useContext(reloadContext);
  const [previousStatus, setPreviousStatus] = useState("");
  const [currentFileId, setCurrentFileId] = useState(null);
  const [currentFileName, setCurrentFileName] = useState(null);
  const [email, setEmail] = useState("");
  const [endMessage, setEndMessage] = useState("");
  const showErrorToast = (message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const MAX_FILE_SIZE = 100 * 1024 * 1024;

  const isValidFileType = (file) => {
    const validTypes = [
      "audio/mpeg", // mp3
      "audio/wav", // WAV
      "audio/ogg", // OGG, OPUS, OGA
      "audio/mp4",
      "audio/x-m4a", // M4A, ALAC
      "audio/vnd.dlna.adts", // AAC
      "audio/flac", // FLAC
      "audio/x-ms-wma", // WMA
      "audio/aiff", // AIFF
      "audio/basic", // AU
      "video/webm", // WEBM
    ];
    return validTypes.includes(file.type);
  };

  const isValidFileSize = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };

  const resetState = () => {
    setCheckStatus(false);
    setStatus("none");
    setIsFileSelected(false);
    setEndMessage("");
    setEmail("");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []) as unknown as File[];
    const validFiles = files.filter(
      (file) => isValidFileType(file) && isValidFileSize(file)
    );
    let invalidFilesByType = files.filter((file) => !isValidFileType(file));
    let invalidFilesBySize = files.filter((file) => !isValidFileSize(file));

    const newFiles = validFiles.map((file) => ({
      file,
      name: file.name,
      progress: 0,
    }));

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setIsTranscribePressed(false);
    resetState();

    if (newFiles.length > 1) {
      showErrorToast("We currently support only 1 file at a time");
      setSelectedFiles([]);
    }

    if (invalidFilesByType.length > 0) {
      console.error(invalidFilesByType);
      showErrorToast("Unsupported File Format");
      setSelectedFiles([]);
    }

    if (invalidFilesBySize.length > 0) {
      console.error(invalidFilesBySize);
      showErrorToast("Max File Size Exceeded (100MB)");
      setSelectedFiles([]);
    }

    if (
      invalidFilesByType.length == 0 &&
      invalidFilesBySize.length == 0 &&
      newFiles.length == 1
    ) {
      setIsFileSelected(true);
    }

    invalidFilesByType = [];
    invalidFilesBySize = [];
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files) as unknown as File[];
    const validFiles = files.filter(
      (file) => isValidFileType(file) && isValidFileSize(file)
    );
    let invalidFilesByType = files.filter((file) => !isValidFileType(file));
    let invalidFilesBySize = files.filter((file) => !isValidFileSize(file));

    const newFiles = validFiles.map((file) => ({
      file,
      name: file.name,
      progress: 0,
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setIsTranscribePressed(false);
    if (newFiles.length > 1) {
      showErrorToast("We currently support only 1 file at a time");
      setSelectedFiles([]);
    }

    if (invalidFilesByType.length > 0) {
      showErrorToast("Unsupported File Format");
      setSelectedFiles([]);
    }

    if (invalidFilesBySize.length > 0) {
      showErrorToast("Max File Size Exceeded (100MB)");
      setSelectedFiles([]);
    }

    if (
      invalidFilesByType.length == 0 &&
      invalidFilesBySize.length == 0 &&
      newFiles.length == 1
    ) {
      setIsFileSelected(true);
    }

    invalidFilesByType = [];
    invalidFilesBySize = [];
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    resetState();
  };

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleTranscribe = () => {
    setIsTranscribePressed(true);
    if (!email || !selectedFiles.length) return;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("type", "STRANGER");
    formData.append("file", selectedFiles[0].file); // Assuming only one file

    const xhr = new XMLHttpRequest();

    xhr.open("POST", "https://lb1.voice-transcribe.com/transcribe", true);

    xhr.setRequestHeader("Authorization", "AmraAlaminBhaierShoinik:)");

    // Track the upload progress
    xhr.upload.onprogress = (event) => {
      setStatus("uploading");
      setEndMessage("Uploading file to the server...");
      if (event.lengthComputable && selectedFiles[0]) {
        const percentComplete = (event.loaded / event.total) * 100;
        const updatedFiles = [...selectedFiles];
        updatedFiles[0] = { ...updatedFiles[0], progress: percentComplete }; // Ensure progress is updated correctly
        setSelectedFiles(updatedFiles);

        if (percentComplete === 100) {
          setStatus("transcribing");
          setEndMessage("Grab a coffee, and we'll email you once it's done!");
        }
      }
    };

    // Handle success or error in response
    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText); // Parse the response
        if (
          response.success === "true" &&
          response.message === "transcript sent to email"
        ) {
          setStatus("completed"); // If transcription is done
          setEndMessage("Transcription completed and sent to your email!");
          toast.success("Transcription completed and sent to your email!");
        } else {
          setStatus("transcribing"); // Handle the transcribing status
          toast.info("Transcription is in progress...");
        }
      } else if (xhr.status === 400) {
        setStatus("failed");
        showErrorToast("Error: Email or file is missing.");
        setEndMessage("Error: Email or file is missing.");
      } else if (xhr.status === 401) {
        setStatus("failed");
        showErrorToast("Error: Invalid API key.");
        setEndMessage("Error: Invalid API key.");
      } else if (xhr.status === 402) {
        setStatus("trynow");
        setEndMessage(
          "You have already transcribed 5 files with this email.  Please Sign Up for free 10 hours without any credit card."
        );
        showErrorToast("Error: Already 5 files transcribed with this email.");
      } else if (xhr.status === 413) {
        setStatus("failed");
        showErrorToast("Error: File larger than 100 MB.");
        setEndMessage("Error: File larger than 100 MB.");
      } else if (xhr.status === 500) {
        setStatus("failed");
        showErrorToast("Please check your email and filename.");
        setEndMessage("Please check your email and filename.");
      } else {
        setStatus("failed");
        showErrorToast("Unexpected error occurred.");
        setEndMessage("Unexpected error occurred.");
      }
      setIsTranscribing(false);
    };

    // Handle network or unexpected error
    xhr.onerror = () => {
      setStatus("failed");
      showErrorToast("Error: Network issue or server error.");
      setEndMessage("Error: Network issue or server error.");
      setIsTranscribing(false);
    };

    // Handle timeout
    xhr.ontimeout = () => {
      setStatus("failed");
      showErrorToast("Error: Upload request timed out.");
      setEndMessage("Error: Upload request timed out.");
      setIsTranscribing(false);
    };

    xhr.timeout = 600000; // Timeout 5 mins (adjust if necessary)

    xhr.send(formData);
  };

  return (
    <>
      <ToastContainer />
      <div className="w-[560px] h-auto pt-4 px-2 bg-white rounded-2xl flex-col justify-center items-center gap-2 inline-flex">
        <h2 className="text-3xl font-bold leading-9 text-dragcontent-700">
          Try Our Transcription Service for Free
        </h2>
        <div className="flex justify-center h-auto w-full">
          {selectedFiles.length === 0 && (
            <div
              className={`bg-white px-4 justify-center items-center rounded-2xl w-full  mt-7 md:my-7 ${
                isDragging ? "border-blue-500" : ""
              }`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <div className="border-2 border-dashed border-dragcontent-700 text-center rounded-lg cursor-pointer p-4 bg-white">
                <div className="flex justify-center items-center mt-4">
                  <Avatar
                    alt="upload"
                    sx={{ width: 28, height: "auto" }}
                    src="./assets/Icons/upload.svg"
                    className="rounded-none"
                  />
                </div>
                <p className="text-base font-bold leading-6 text-dragcontent-700 mt-2">
                  Drag and drop your files here
                </p>
                <div className="text-sm font-normal leading-5 text-contenthead-800 mt-2 flex justify-center text-center items-center">
                  <strong>
                    File Supported: Any Audio File Format (100MB limit){" "}
                  </strong>
                  <Tooltip
                    title="mp3, wav, m4a, ogg, aac, flac, webm, wma, opus, oga, aiff, au, alac"
                    placement="right"
                  >
                    <Avatar
                      alt="info"
                      sx={{ width: 20, height: "auto" }}
                      src="./assets/Icons/vector.svg"
                      className="rounded-none mx-1"
                    />
                  </Tooltip>
                </div>
                <Button
                  component="label"
                  variant="contained"
                  className="sign-up-btn  hover:to-heading-800 bg-gradient-to-r  sm:w-auto shadow-none from-heading-800 from-head-600 to-low-400 text-base leading-6 text-white bg-white  font-bold py-2 px-10 rounded-lg mt-4 w-auto h-auto capitalize"
                >
                  Browse File
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    accept=".ogg,.mp3,.wav,.m4a,.opus,.aac,.flac,.wma,.aiff,.oga,.au,.webm,.alac"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
              <p id="apiResponse"></p>
            </div>
          )}
        </div>
        <div className="pb-4  w-full">
          <div className="space-y-4 ">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between bg-body-600 py-2 pl-4 rounded-md relative"
              >
                <div className="flex items-center gap-2 w-[90%]">
                  <Avatar
                    alt="Music Icon"
                    sx={{ width: 21, height: "auto" }}
                    src="./assets/Icons/music-icon.svg"
                    className="rounded-none"
                  />
                  <span className="text-base text-contenthead-800 leading-6 font-medium">
                    {shortenFileName(file.name, 50)}
                  </span>
                </div>

                <div className="flex items-center gap-6 mr-4">
                  {IsFileSelected &&
                    !isTranscribing &&
                    !isTranscribePressed && (
                      <button
                        className="text-white bg-[#FF0000] rounded-full w-6 h-6 flex items-center justify-center"
                        onClick={() => handleRemoveFile(index)}
                      >
                        X
                      </button>
                    )}
                  {IsFileSelected &&
                    status === "uploading" &&
                    isTranscribePressed && (
                      <div className="text-white bg-teal-700 rounded-lg px-2 flex items-center justify-center">
                        {`${Math.round(file.progress)}% `}
                        {/* Uploading svg */}
                        <svg
                          className="pl-2"
                          fill="#ffffff"
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384.97 384.97"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <g id="Upload">
                                {" "}
                                <path d="M372.939,264.641c-6.641,0-12.03,5.39-12.03,12.03v84.212H24.061v-84.212c0-6.641-5.39-12.03-12.03-12.03 S0,270.031,0,276.671v96.242c0,6.641,5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03v-96.242 C384.97,270.019,379.58,264.641,372.939,264.641z"></path>{" "}
                                <path d="M117.067,103.507l63.46-62.558v235.71c0,6.641,5.438,12.03,12.151,12.03c6.713,0,12.151-5.39,12.151-12.03V40.95 l63.46,62.558c4.74,4.704,12.439,4.704,17.179,0c4.74-4.704,4.752-12.319,0-17.011l-84.2-82.997 c-4.692-4.656-12.584-4.608-17.191,0L99.888,86.496c-4.752,4.704-4.74,12.319,0,17.011 C104.628,108.211,112.327,108.211,117.067,103.507z"></path>{" "}
                              </g>{" "}
                              <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                              <g> </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </div>
                    )}
                  {status === "transcribing" && (
                    <div className="text-white bg-heading-800 rounded-lg px-2 flex items-center justify-center">
                      Transcribing
                    </div>
                  )}
                  {status === "completed" && (
                    <div className="text-white  bg-teal-700 rounded-lg px-2 flex items-center justify-center">
                      Completed
                    </div>
                  )}
                  {status === "trynow" && (
                    <div className="text-white bg-heading-800 rounded-lg px-2 flex items-center justify-center">
                      SignUp
                    </div>
                  )}
                  {status === "failed" && (
                    <div className="text-white bg-red-700 rounded-lg px-2 flex items-center justify-center">
                      Failed
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {selectedFiles.length > 0 && !isTranscribePressed && (
            <>
              <form className="mt-4">
                {/* <label className="text-base text-heading-800 font-medium">
                  Enter your email to receive the transcription:
                </label> */}
                <input
                  className="space-y-2 bg-body-600 rounded-lg text-base text-contenthead-800 leading-6 font-medium py-3 px-5 mt-2 w-full"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleTranscribe();
                    }
                  }}
                  required
                />
              </form>
              {email ? (
                <div className="flex items-center justify-center mt-6 flex-wrap gap-6">
                  <Button
                    variant="outlined"
                    className="hover:to-heading-800 bg-gradient-to-r from-heading-800  to-low-400 text-white text-base font-bold px-10 py-2 rounded-lg border-none capitalize"
                    onClick={handleTranscribe}
                  >
                    Transcribe
                  </Button>
                </div>
              ) : (
                <div className="flex text-base items-center justify-center mt-4 mb-2 text-contenthead-800 flex-wrap gap-6 font-medium">
                  We will send your file to your email after it is transcribed
                </div>
              )}
            </>
          )}
          {endMessage !== "" && (
            <>
              {status === "trynow" && (
                <div className="flex items-center text-red-800 justify-center mt-4 mb-2 flex-wrap gap-6 font-medium text-center">
                  {endMessage}
                </div>
              )}
              {(status === "completed" ||
                status === "uploading" ||
                status === "transcribing") && (
                <div className="flex items-center justify-center mt-4 mb-2 text-teal-800 flex-wrap gap-6 font-medium text-center">
                  {endMessage}
                </div>
              )}
              {status === "failed" && (
                <div className="flex items-center text-red-800 justify-center mt-4 mb-2 flex-wrap gap-6 font-medium text-center">
                  {endMessage}
                </div>
              )}
            </>
          )}
          {status === "completed" && (
            <div className="flex items-center justify-center mt-4">
              <Button
                variant="outlined"
                className="bg-gradient-to-r from-teal-800 to-teal-600 hover:to-teal-800 text-white text-base font-bold px-10 py-2 rounded-lg border-none capitalize"
                onClick={() => {
                  resetState();
                  setSelectedFiles([]);
                  setEndMessage("");
                }}
              >
                Transcribe Another
              </Button>
            </div>
          )}
          {status === "trynow" && (
            <div className="flex items-center justify-center mt-4">
              <Button
                variant="outlined"
                className="bg-gradient-to-r from-heading-800 to-low-400 hover:to-heading-800 text-white text-base font-bold px-10 py-2 rounded-lg border-none capitalize"
                onClick={() => {
                  window.location.href = SIGNUP_URL;
                }}
              >
                Sign Up for Free
              </Button>
            </div>
          )}

          {status === "failed" && (
            <div className="flex items-center justify-center mt-4">
              <Button
                variant="outlined"
                className="bg-gradient-to-r from-red-800 to-red-600 hover:to-red-800 text-white text-base font-bold px-10 py-2 rounded-lg border-none capitalize"
                onClick={() => {
                  resetState();
                  setSelectedFiles([]);
                  setEndMessage("");
                }}
              >
                Try Again
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TrialUploadSection;
